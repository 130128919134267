export const path = {
  home: "/",
  login: "/login",
  register: "/register",
  logout: "/logout",
  profile: "/dashboard/profile",
  setting: "/dashboard/setting",
  dashboard: "/dashboard",
  recovery: "/recovery",
  reset: "/reset-password/:token",
  album: "/dashboard/albums/:albumId",
  albumAno: "/albums/:albumId",
  servicepackages: "/service-packages",
  packageManagement: "/dashboard/package-management",
  staticpage: "/page/:path",
};
