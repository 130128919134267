import JSZip from "jszip";
import { saveAs } from "file-saver";

class ZipDownloader {
  private zip: JSZip;

  constructor() {
    this.zip = new JSZip();
  }

  async add(url: string): Promise<void> {
    const response = await fetch(url);
    const blob = await response.blob();

    let fileName = url.substring(url.lastIndexOf("/") + 1);

    const contentType = response.headers.get("Content-Type");
    if (contentType) {
      const extension = contentType.split("/")[1];
      if (extension && !fileName.includes(".")) {
        fileName += `.${extension}`;
      }
    }

    this.zip.file(fileName, blob);
  }

  async addMulti(
    filelist: { url: string; name: string; folder_name: string }[],
    chunkSize: number = 50,
    name: string = "list"
  ): Promise<void> {
    const chunkDownload = Math.min(10, chunkSize); // Don't download more than chunk size
    const totalChunks = Math.ceil(filelist.length / chunkSize);
    
    for (let chunk = 0; chunk < totalChunks; chunk++) {
      const start = chunk * chunkSize;
      const end = Math.min(start + chunkSize, filelist.length);
      
      // Process files in smaller batches for memory efficiency
      for (let i = start; i < end; i += chunkDownload) {
        const batch = filelist.slice(i, Math.min(i + chunkDownload, end));
        await Promise.all(
          batch.map(async (file) => {
            const response = await fetch(file.url);
            const blob = await response.blob();
            this.zip.file(file.folder_name + "/" + file.name, blob);
          })
        );
      }

      // Save chunk and create new zip
      if (chunk < totalChunks - 1) {
        await this.save(`${name}_${chunk + 1}`);
        this.zip = new JSZip();
      }
    }
  }

  file(name: string, content: string): void {
    this.zip.file(name, content);
  }

  async save(name: string): Promise<void> {
    const content = await this.zip.generateAsync({ type: "blob" });
    saveAs(content, `${name}.zip`);
  }
}

export default ZipDownloader;
