import { Button, Skeleton } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useGetMembershipPlansApi } from "../../services/apis/payment.api";
import { useRootSelector } from "../../stores/reducers/root";
import { TMembershipPlanPrice } from "../../types/membership.type";
import { getPackageFromIdPrice, useCheckUnit } from "../../utils/membership";

const ExtendPackage = ({
  onClick,
}: {
  onClick: (data: TMembershipPlanPrice) => void;
}) => {
  const { t } = useTranslation();
  const checkUnit = useCheckUnit();
  const { data: membershipPlans, isLoading: membershipPlansLoading } =
    useGetMembershipPlansApi();
  const user = useRootSelector((state) => state.user.userProfile);
  const currentPackage = getPackageFromIdPrice(
    user?.subscription?.membership_plan_id,
    membershipPlans?.data
  );
  const currentOption = currentPackage?.membership_plan_price?.find(
    (item) => item?.duration === 12
  );
  if (membershipPlansLoading)
    return (
      <div className="overflow-hidden">
        <Skeleton active />
      </div>
    );

  return (
    <div className="flex flex-col gap-6">
      <span>
        {t("managerPackage.extendModal.content", {
          package: currentPackage?.name,
        })}
      </span>
      <div className="flex items-center justify-around">
        <div className="flex flex-col items-center">
          <span>{t("managerPackage.extendModal.name")}</span>
          <span className="text-xl font-bold">{currentPackage?.name}</span>
        </div>
        <div className="flex flex-col items-center">
          <span>{t("managerPackage.extendModal.money")}</span>
          <span className="text-xl font-bold">
            {currentOption?.[checkUnit]?.toLocaleString("de-DE") +
              t("servicePackages.payment.priceUnit")}
          </span>
        </div>
      </div>
      <div className="flex items-center justify-around">
        <div className="flex flex-col items-center">
          <span>{t("managerPackage.extendModal.startDate")}</span>
          <span className="text-xl font-bold">
            {dayjs()?.format("DD/MM/YYYY")}
          </span>
        </div>
        <div className="flex flex-col items-center">
          <span>{t("managerPackage.extendModal.endDate")}</span>
          <span className="text-xl font-bold">
            {dayjs(user?.subscription?.end_date)
              ?.add(12, "month")
              ?.format("DD/MM/YYYY")}
          </span>
        </div>
      </div>
      <Button
        type="primary"
        onClick={() => {
          onClick && onClick(currentOption);
        }}
        className="success transition-all mt-4 font-medium w-28 m-auto"
      >
        {t("servicePackages.payment.btn")}
      </Button>
    </div>
  );
};

export default ExtendPackage;
