import { Mail, MessageSquare, Phone } from "react-feather";
import { useTranslation } from "react-i18next";

const ContactBlock = () => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center space-x-4 lg:space-x-10 py-10 flex-wrap flex-col md:flex-row">
      <span>{t("servicePackages.help.title")}</span>
      <a
        href="https://m.me/NguyenHoangPhucPhoto"
        target="_blank"
        rel="noreferrer"
        className="flex gap-1 items-center hover:text-secondary transition-all"
      >
        <MessageSquare className="w-4" />
        {t("servicePackages.help.mess")}
      </a>
      <a
        href={`tel:${t("servicePackages.help.phoneNumber")}`}
        target="_blank"
        rel="noreferrer"
        className="flex gap-1 items-center hover:text-secondary transition-all"
      >
        <Phone className="w-4" />
        {t("servicePackages.help.phone")}
      </a>
      <a
        href={`mailto:${t("servicePackages.help.contact")}`}
        target="_blank"
        rel="noreferrer"
        className="flex gap-1 items-center hover:text-secondary transition-all"
      >
        <Mail className="w-4" />
        {t("servicePackages.help.contact")}
      </a>
    </div>
  );
};

export default ContactBlock;
