import { Spin } from 'antd';

const LoadingPage = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-white/60">
      <Spin size="large" />
    </div>
  );
};

export default LoadingPage;
