import { createSlice } from '@reduxjs/toolkit';
import { TAuthStore } from '../../types/auth.type';
import { getAccessTokenFromLS } from '../../utils/localStorage';
import { validateToken } from '../../utils/validateToken';

const initialState: TAuthStore = {
  isAuthenticated: Boolean(validateToken(getAccessTokenFromLS())),
  passAlbum: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setIsAuthenticated: (state, action) => {
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    },
    setPassAlbum: (state, action) => {
      return {
        ...state,
        passAlbum: action.payload,
      };
    },
  },
});

export const { setIsAuthenticated, setPassAlbum } = authSlice.actions;

export default authSlice.reducer;
