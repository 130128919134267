import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import { useGetMeApi } from '../services/apis/user.api';

import defineAbilityUser, { AbilityContext } from '../services/ability.service';
import { useRootSelector } from '../stores/reducers/root';
import { useMemo } from 'react';

const MainLayout = () => {
  const userProfile = useGetMeApi();

  const user = useRootSelector((state) => state.user.userProfile);
  const ability = useMemo(() => defineAbilityUser(user), [user]);

  return (
    <AbilityContext.Provider value={ability}>
      <Header user={userProfile.data?.data?.data} />
      <div className="max-w-[1440px] m-auto sm:px-8 px-4">
        <Outlet />
      </div>
    </AbilityContext.Provider>
  );
};

export default MainLayout;
