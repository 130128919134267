import { Button } from 'antd';
import { Home } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import notFoundImg from '../../assets/images/not-found.svg';
import { path } from '../../routes/path';
import { useRootSelector } from '../../stores/reducers/root';

const Notfound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAuthenticated = useRootSelector(
    (state) => state.auth.isAuthenticated
  );

  return (
    <div className="fixed top-0 left-0 right-0 h-screen flex flex-col justify-center items-center z-50 bg-white">
      <img alt="not-found-img" src={notFoundImg} className="" />
      <div className="flex items-center justify-center text-center px-4 flex-col sm:flex-row mt-10 mb-5 md:mt-14 md:mb-8">
        <h1 className="text-2xl sm:text-3xl font-extrabold text-slate-900 tracking-tight sm:pr-6 sm:mr-6 sm:border-r sm:border-slate-900/10 sm:dark:border-slate-300/10 dark:text-slate-200">
          404
        </h1>
        <h2 className="mt-2 text-lg text-slate-700 dark:text-slate-400 sm:mt-0">
          {t('notFound.title')}
        </h2>
      </div>
      <Button
        type="default"
        className="transition-all success items-center flex"
        onClick={() => {
          isAuthenticated ? navigate(path.dashboard) : navigate(path.home);
        }}
      >
        <Home className="w-4" />
        {t('notFound.btnBack')}
      </Button>
    </div>
  );
};

export default Notfound;
