import { createSlice } from '@reduxjs/toolkit';
import { TSortSearch } from '../../types/common.type';

const initialState: TSortSearch = {
  mode: 'created_at desc',
  valueSearch: '',
};

const sortSearchSlice = createSlice({
  name: 'sortModeSlice',
  initialState: initialState,
  reducers: {
    setSortMode: (state, action) => {
      return {
        ...state,
        mode: action.payload,
      };
    },
    setValueSearch: (state, action) => {
      return {
        ...state,
        valueSearch: action.payload,
      };
    },
  },
});

export const { setSortMode, setValueSearch } = sortSearchSlice.actions;

export default sortSearchSlice.reducer;
