import { Skeleton } from "antd";
import { useTranslation } from "react-i18next";

type TBanner = {
  name: string;
  customer_name: string;
  thumbnail_url: string;
};

const BannerAlbum = ({
  data,
  isLoading,
}: {
  data: TBanner;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();

  if (isLoading)
    return (
      <div className="w-full overflow-hidden">
        <Skeleton active />
      </div>
    );

  return (
    <div className="relative w-full flex">
      <img
        src={data.thumbnail_url}
        className="absolute top-0 left-0 w-full h-full object-cover"
        alt="photo-detail"
      />
      <div className="pb-[30%]"></div>
      <div
        className={`absolute inset-0 ${
          !data.thumbnail_url && "bg-gray-400"
        } justify-end p-4 md:px-7 md:py-10 text-white text-lg md:text-3xl flex flex-col md:gap-1`}
      >
        <span className="font-semibold">{data.name}</span>
        <div className="text-gray-50 text-sm md:text-base">
          {t("albumDetail.customer") + " " + data.customer_name}
        </div>
      </div>
    </div>
  );
};

export default BannerAlbum;
