import { Button, Skeleton } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useCalculateUpgradeApi,
  useGetMembershipPlansApi,
} from "../../services/apis/payment.api";
import { useRootSelector } from "../../stores/reducers/root";
import { TMembershipPlanPrice } from "../../types/membership.type";
import ListPackage from "../ServicePackages/ListPackage";

const UpgradePackage = ({
  onClick,
}: {
  onClick: (data: TMembershipPlanPrice) => void;
}) => {
  const { t } = useTranslation();
  const [upgrade, setUpgrade] = useState<{
    membershipId?: number;
    refund?: number;
    total?: number;
    duration?: number;
  }>();
  const user = useRootSelector((state) => state.user.userProfile);
  const { data: calculateUpgrade } = useCalculateUpgradeApi();
  const { data: membershipPlans, isLoading: membershipPlansLoading } =
    useGetMembershipPlansApi();
  const upgradePackages = membershipPlans?.data?.filter(
    (item) => item.id > (user?.subscription?.membership_plan_id || 0)
  );
  const upgradeOptions = upgradePackages
    ?.map((option) => option?.membership_plan_price)
    .flat();

  if (membershipPlansLoading)
    return (
      <div className="overflow-hidden">
        <Skeleton active />
      </div>
    );

  return (
    <div className="flex flex-col">
      <div>
        {t("managerPackage.upgradeModal.name") + " "}
        <span className="text-lg font-semibold">
          {user?.membership_plan?.name}
        </span>
      </div>
      <ListPackage
        listPlanPrice={upgradeOptions}
        calculateUpgrade={calculateUpgrade}
        membershipPlans={membershipPlans?.data}
        onClick={({ membershipPlanPriceId, price, duration }) => {          
          setUpgrade({
            total:
              price -
              (calculateUpgrade?.data ? calculateUpgrade?.data?.refund : 0),
            membershipId: membershipPlanPriceId,
            duration: duration,
            refund: calculateUpgrade?.data?.refund || 0,
          });
        }}
      />
      <div className="flex flex-col py-2 border-y">
        <div>
          {t("managerPackage.upgradeModal.refund") + " "}
          <span className="font-semibold">
            {upgrade?.refund?.toLocaleString("de-DE") +
              t("servicePackages.payment.priceUnit")}
          </span>
        </div>
        <div className="font-semibold">
          {t("managerPackage.upgradeModal.money") + " "}
          <span>
            {upgrade?.total?.toLocaleString("de-DE") +
              t("servicePackages.payment.priceUnit")}
          </span>
        </div>
      </div>
      <div className="py-2">
        {t("managerPackage.upgradeModal.newTime") + " "}
        <span className="font-bold">
          {dayjs(user?.subscription?.start_date || new Date()).format("DD/MM/YYYY") +
            " - " +
            dayjs(user?.subscription?.start_date || new Date())
              .add(upgrade?.duration || 12, "month")
              .format("DD/MM/YYYY")}
        </span>
      </div>
      <Button
        type="primary"
        onClick={() => {
          onClick &&
            onClick(
              upgradeOptions?.find((item) => item?.id === upgrade?.membershipId)
            );
        }}
        className="success transition-all mt-4 font-medium w-28 m-auto"
      >
        {t("servicePackages.payment.btn")}
      </Button>
    </div>
  );
};

export default UpgradePackage;
