import { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { queryKeys } from "../../constants/queryKeys";
import { setIsAuthenticated } from "../../stores/reducers/auth.reducer";
import store from "../../stores/reducers/root";
import { setUser } from "../../stores/reducers/user.reducer";
import { TGetProfileResponse } from "../../types/user.type";
import { userEndpoint } from "../endpoints";
import http from "../http.service";

export const useGetMeApi = (enabled?: boolean) => {
  const dispath = useDispatch();
  const query = useQuery(
    [queryKeys.GET_ME],
    async () => {
      const res = await http.get<TGetProfileResponse>(userEndpoint.PROFILE);
      return res;
    },
    {
      enabled: enabled,
      onSuccess: (data) => {
        if (data?.data.data) {
          dispath(setUser(data?.data.data));
        }
      },
      onError: () => {
        store.dispatch(setIsAuthenticated(false));
      },
    }
  );

  return query;
};

export const useUpdateProfileApi = () => {
  const mutation = useMutation<
    AxiosResponse<{ success: boolean }, any>,
    AxiosError<any>,
    any,
    unknown
  >({
    mutationFn: async (data: any) => {
      const url = `${userEndpoint.PROFILE}`;
      return await http.post<{ success: boolean }>(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
  });
  return mutation;
};
export const useUpdateSetting = () => {
  const mutation = useMutation<
    AxiosResponse<{ success: boolean }, any>,
    AxiosError<any>,
    any,
    unknown
  >({
    mutationFn: async (data: any) => {
      const url = `${userEndpoint.SETTING}`;
      return await http.post<{ success: boolean }>(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
  });
  return mutation;
};
