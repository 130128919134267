import { useTranslation } from "react-i18next";
import { MdAttachMoney, MdSecurity } from "react-icons/md";
import { FiUserCheck } from "react-icons/fi";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { Button } from "antd";
import Footer from "../../components/Footer";
import { useNavigate } from "react-router-dom";
import { path } from "../../routes/path";
import homepageImg from "../../assets/images/homepage.webp";
import { useRootSelector } from "../../stores/reducers/root";
import smartphoneImg from "../../assets/images/smartphone.webp";
import {
  Camera,
  CheckCircle,
  MousePointer,
  Send,
  UploadCloud,
} from "react-feather";
import { CgArrowLongRight, CgArrowLongDown } from "react-icons/cg";
import { useEffect, useState } from "react";

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAuthenticated = useRootSelector(
    (state) => state.auth.isAuthenticated
  );
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const toggleVisibility = () => {
    if (window.scrollY > 30) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div>
      <div
        className={`bg-gray-100 ${
          !isVisible ? "pt-14 md:pt-16 -mt-14 md:-mt-16" : ""
        }`}
      >
        <div className="py-8">
          <div className="max-w-[1440px] m-auto sm:px-8 px-4 flex flex-col items-center">
            <span className="text-xl sm:text-2xl uppercase text-center">
              {t("home.banner.subTitle")}
            </span>
            <span className="text-2xl sm:text-3xl font-bold uppercase text-center">
              {t("home.banner.title")}
            </span>
            <span className="text-sm sm:text-base mt-2 text-center">
              {t("home.banner.content")}
            </span>
            <Button
              onClick={() => {
                if (isAuthenticated) navigate(path.dashboard);
                else navigate(path.login);
              }}
              type="primary"
              className="mt-8"
            >
              {t("home.banner.btn")}
            </Button>
            <div className="w-full sm:w-2/3 m-auto mt-8">
              <img
                src={homepageImg}
                alt="homepage"
                className="h-full w-full object-contain"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white py-8">
        <div className="max-w-[1440px] m-auto sm:px-8 px-4 flex flex-col gap-5 items-center">
          <div className="flex flex-col items-center">
            <span className="text-base sm:text-lg">
              {t("home.reason.subTitle")}
            </span>
            <span className="text-xl sm:text-2xl font-semibold uppercase">
              {t("home.reason.title")}
            </span>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8 lg:gap-10">
            <div className="flex flex-col group items-center h-full cursor-default hover:bg-gray-100 transition-all max-w-60 p-3 rounded-lg">
              <div className="h-[80px] md:h-[72px] flex flex-col items-center gap-1 md:gap-2">
                <MdAttachMoney className="text-3xl" />
                <span className="text-third font-semibold text-center">
                  {t("home.reason.cheapest.title")}
                </span>
              </div>
              <span className="mt-4 text-center">
                {t("home.reason.cheapest.content")}
              </span>
            </div>
            <div className="flex flex-col group items-center h-full cursor-default hover:bg-gray-100 transition-all max-w-60 p-3 rounded-lg">
              <div className="h-[80px] md:h-[72px] flex flex-col items-center gap-1 md:gap-2">
                <MdSecurity className="text-3xl" />
                <span className="text-third font-semibold text-center">
                  {t("home.reason.secure.title")}
                </span>
              </div>
              <span className="mt-4 text-center">
                {t("home.reason.secure.content")}
              </span>
            </div>
            <div className="flex flex-col group items-center h-full cursor-default hover:bg-gray-100 transition-all max-w-60 p-3 rounded-lg">
              <div className="h-[80px] md:h-[72px] flex flex-col items-center gap-1 md:gap-2">
                <FiUserCheck className="text-3xl" />
                <span className="text-third font-semibold text-center">
                  {t("home.reason.easyUse.title")}
                </span>
              </div>
              <span className="mt-4 text-center">
                {t("home.reason.easyUse.content")}
              </span>
            </div>
            <div className="flex flex-col group items-center h-full cursor-default hover:bg-gray-100 transition-all max-w-60 p-3 rounded-lg">
              <div className="h-[80px] md:h-[72px] flex flex-col items-center gap-1 md:gap-2">
                <IoPhonePortraitOutline className="text-3xl" />
                <span className="text-third font-semibold text-center w-36 md:w-fit">
                  {t("home.reason.convenient.title")}
                </span>
              </div>
              <span className="mt-4 text-center">
                {t("home.reason.convenient.content")}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 py-10 lg:py-20">
        <div className="relative max-w-[1440px] m-auto sm:px-8 px-4 flex justify-center sm:flex-row flex-col gap-6 items-center">
          <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-y-60 gap-y-6 w-full sm:w-1/2 lg:w-full">
            <div className="relative flex flex-col gap-1 items-center justify-center w-60 m-auto">
              <span className="text-third font-semibold">
                {t("home.feature.logo.title")}
              </span>
              <span className="text-center">
                {t("home.feature.logo.subTitle")}
              </span>
              <div className="hidden lg:block absolute -bottom-1/2 -right-1/4">
                <svg width="150" height="50">
                  <g transform="rotate(180, 75, 50)">
                    <path
                      d="M 10 80 Q 50 50 180 80"
                      stroke="black"
                      fill="transparent"
                      strokeDasharray="5,5"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div className="relative flex flex-col gap-1 items-center justify-center w-60 m-auto lg:mt-8">
              <span className="text-third font-semibold">
                {t("home.feature.share.title")}
              </span>
              <span className="text-center">
                {t("home.feature.share.subTitle")}
              </span>
              <div className="hidden lg:block absolute -bottom-1/2 -left-1/2 -rotate-[25]">
                <svg width="200" height="50">
                  <g transform="rotate(180, 100, 50)">
                    <path
                      d="M 10 80 Q 30 30 190 80"
                      stroke="black"
                      fill="transparent"
                      strokeDasharray="5,5"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div className="relative flex flex-col gap-1 items-center justify-center w-60 m-auto lg:-mt-8">
              <span className="text-third font-semibold">
                {t("home.feature.smartphone.title")}
              </span>
              <span className="text-center">
                {t("home.feature.smartphone.subTitle")}
              </span>
              <div className="hidden lg:block absolute -bottom-1/2 -right-1/2">
                <svg width="200" height="50">
                  <g transform="rotate(180, 100, 50)">
                    <path
                      d="M 10 80 Q 30 30 190 80"
                      stroke="black"
                      fill="transparent"
                      strokeDasharray="5,5"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div className="relative flex flex-col gap-1 items-center justify-center w-60 m-auto">
              <span className="text-third font-semibold">
                {t("home.feature.face.title")}
              </span>
              <span className="text-center">
                {t("home.feature.face.subTitle")}
              </span>
              <div className="hidden lg:block absolute -bottom-1/2 -left-1/4">
                <svg width="150" height="50">
                  <g transform="rotate(180, 75, 50)">
                    <path
                      d="M 10 80 Q 50 50 180 80"
                      stroke="black"
                      fill="transparent"
                      strokeDasharray="5,5"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
          <div className="relative lg:absolute lg:left-1/2 lg:top-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2 lg:w-1/4 sm:w-1/3 w-1/2">
            <img
              src={smartphoneImg}
              alt="smartphone"
              className="w-full h-full object-contain"
            />
          </div>
        </div>
      </div>

      <div className="bg-white py-12">
        <div className="max-w-[1440px] m-auto sm:px-8 px-4 flex flex-col gap-8 items-center">
          <div className="flex flex-col items-center">
            <span className="text-base sm:text-lg">
              {t("home.easy.subTitle")}
            </span>
            <span className="text-xl sm:text-2xl font-semibold uppercase">
              {t("home.easy.title")}
            </span>
          </div>
          <div className="flex justify-between items-center gap-5 lg:gap-8 md:flex-row flex-col">
            <div className="flex flex-col gap-2 items-center justify-between">
              <Camera className="w-6" />
              <span className="text-third font-semibold">
                {t("home.easy.step1")}
              </span>
            </div>
            <div className="flex items-center justify-center">
              <CgArrowLongRight className="text-3xl text-gray-400 md:block hidden" />
              <CgArrowLongDown className="text-3xl text-gray-400 block md:hidden" />
            </div>
            <div className="flex flex-col gap-2 items-center justify-between">
              <UploadCloud className="w-6" />
              <span className="text-third font-semibold">
                {t("home.easy.step2")}
              </span>
            </div>
            <div className="flex items-center justify-center">
              <CgArrowLongRight className="text-3xl text-gray-400 md:block hidden" />
              <CgArrowLongDown className="text-3xl text-gray-400 block md:hidden" />
            </div>
            <div className="flex flex-col gap-2 items-center justify-between">
              <Send className="w-6" />
              <span className="text-third font-semibold">
                {t("home.easy.step3")}
              </span>
            </div>
            <div className="flex items-center justify-center">
              <CgArrowLongRight className="text-3xl text-gray-400 md:block hidden" />
              <CgArrowLongDown className="text-3xl text-gray-400 block md:hidden" />
            </div>
            <div className="flex flex-col gap-2 items-center justify-between">
              <MousePointer className="w-6" />
              <span className="text-third font-semibold">
                {t("home.easy.step4")}
              </span>
            </div>
            <div className="flex items-center justify-center">
              <CgArrowLongRight className="text-3xl text-gray-400 md:block hidden" />
              <CgArrowLongDown className="text-3xl text-gray-400 block md:hidden" />
            </div>
            <div className="flex flex-col gap-2 items-center justify-between">
              <CheckCircle className="w-6" />
              <span className="text-third font-semibold">
                {t("home.easy.step5")}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="py-10 bg-gray-100">
        <div className="max-w-[1440px] m-auto sm:px-8 px-4 relative flex flex-col items-center w-full">
          <span className="text-base sm:text-lg">
            {t("servicePackages.explore.subTitle")}
          </span>
          <span className="text-xl sm:text-2xl font-semibold">
            {t("servicePackages.explore.title")}
          </span>
          <div
            dangerouslySetInnerHTML={{
              __html: t("servicePackages.explore.content"),
            }}
            className="py-4 text-xs sm:text-base text-center"
          ></div>
          <Button
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
            type="primary"
          >
            {t("servicePackages.explore.btn")}
          </Button>
          <div className="mt-6 text-xs sm:text-base text-center">
            <span
              className="text-third cursor-pointer hover:opacity-80"
              onClick={() => {
                navigate(path.servicepackages);
              }}
            >
              {t("home.explore.btnViewPrice")}
            </span>
            {t("home.explore.btnViewPriceSub")}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
