import { AxiosError, AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setIsAuthenticated } from '../../stores/reducers/auth.reducer';
import {
  TForgotPassResponse,
  TLoginResponse,
  TRegisterResponse,
} from '../../types/auth.type';
import { setAccessTokenToLS } from '../../utils/localStorage';
import { authEndpoints } from '../endpoints';
import http from '../http.service';
import { path } from './../../routes/path';

export const useLoginApi = () => {
  let navigate = useNavigate();
  const dispath = useDispatch();
  const mutation = useMutation({
    mutationFn: async (dataQuery: any) => {
      return await http.post<TLoginResponse>(authEndpoints.LOGIN, dataQuery);
    },
    onSuccess(data, variables, context) {
      if (data.data?.token) {
        setAccessTokenToLS(data.data?.token);
        dispath(setIsAuthenticated(true));
        navigate(path.dashboard);
      }
    },
  });
  return mutation;
};

export const useRegisterApi = () => {
  const mutation = useMutation({
    mutationFn: async (dataQuery: any) => {
      return await http.post<TRegisterResponse>(
        authEndpoints.REGISTER,
        dataQuery
      );
    },
  });
  return mutation;
};

export const useForgotPassword = () => {
  const mutation = useMutation<
    AxiosResponse<TForgotPassResponse, any>,
    AxiosError<any>,
    any,
    unknown
  >({
    mutationFn: async (dataQuery: any) => {
      return await http.post<TForgotPassResponse>(
        authEndpoints.FORGOTPASS,
        dataQuery
      );
    },
  });
  return mutation;
};

export const useResetPassword = () => {
  const mutation = useMutation<
    AxiosResponse<TForgotPassResponse, any>,
    AxiosError<any>,
    any,
    unknown
  >({
    mutationFn: async (dataQuery: any) => {
      return await http.post<TForgotPassResponse>(
        authEndpoints.RESETPASS,
        dataQuery
      );
    },
  });
  return mutation;
};
