import { Button, Checkbox, Form, Radio } from "antd";
import { useEffect, useState } from "react";
import { Download } from "react-feather";
import { useTranslation } from "react-i18next";
import { useGetAlbumApi } from "../../services/apis/album.api";
import ZipDownloader from "../../services/zip.service";
import {
  getDownloadOptionsFromLS,
  setDownloadOptionsToLS,
} from "../../utils/localStorage";
import { TImage } from "../../types/album.type";

const DOWNLOAD_ALBUM = {
  OPTION: {
    ALL: "all",
    RECOMMEND: "recommend",
    LIKE: "like",
  } as const,
  FORMAT_FILE: {
    DEFAULT: "default",
    TXT: "txt",
  } as const,
  FORMAT_FILE_TXT: {
    COMMA: "1",
    SPACE: "2",
    DOWNLINE: "3",
    EXTENTION: "4",
  } as const,
  INSERT_COMMENT: {
    YES: "1",
    NO: "0",
  } as const,
};

type OptionType =
  (typeof DOWNLOAD_ALBUM.OPTION)[keyof typeof DOWNLOAD_ALBUM.OPTION];
type FormatFileType =
  (typeof DOWNLOAD_ALBUM.FORMAT_FILE)[keyof typeof DOWNLOAD_ALBUM.FORMAT_FILE][];
type InsertCommentType =
  (typeof DOWNLOAD_ALBUM.INSERT_COMMENT)[keyof typeof DOWNLOAD_ALBUM.INSERT_COMMENT];

interface FormData {
  optionDownload: OptionType;
  formatDownload: FormatFileType;
  commentDownload: InsertCommentType;
}

export type TDownloadOptions = FormData & { formatDownloadTxt?: string[] };

const DownloadAlbum = ({
  id,
  totalPhotos,
  onClick,
}: {
  id: string;
  totalPhotos: number;
  onClick?: () => void;
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: albumDetail,
    refetch: refetchAlbum,
    isLoading: loadingAlbum,
    isFetching,
  } = useGetAlbumApi({
    perPage: totalPhotos,
    albumIdProps: id,
  });
  let images = albumDetail?.pages[0]?.data?.data?.files?.data;

  const formatTxtOptions = [
    {
      label: t(
        "dashboard.albums.action.download.modalDownload.formatTxtDownload.value1"
      ),
      value: DOWNLOAD_ALBUM.FORMAT_FILE_TXT.COMMA,
      join: ",",
    },
    {
      label: t(
        "dashboard.albums.action.download.modalDownload.formatTxtDownload.value2"
      ),
      value: DOWNLOAD_ALBUM.FORMAT_FILE_TXT.SPACE,
      join: " ",
    },
    {
      label: t(
        "dashboard.albums.action.download.modalDownload.formatTxtDownload.value3"
      ),
      value: DOWNLOAD_ALBUM.FORMAT_FILE_TXT.DOWNLINE,
      join: "\n",
    },
    {
      label: t(
        "dashboard.albums.action.download.modalDownload.formatTxtDownload.value4"
      ),
      value: DOWNLOAD_ALBUM.FORMAT_FILE_TXT.EXTENTION,
      join: "",
    },
  ];

  const formatOptions = [
    {
      label: t(
        "dashboard.albums.action.download.modalDownload.formatDownload.value1"
      ),
      value: DOWNLOAD_ALBUM.FORMAT_FILE.DEFAULT,
    },
    {
      label: t(
        "dashboard.albums.action.download.modalDownload.formatDownload.value2"
      ),
      value: DOWNLOAD_ALBUM.FORMAT_FILE.TXT,
      children: true,
    },
  ];
  const allOptionsTxt = formatTxtOptions.map((option) => option.value);
  const [showOptionTxt, setShowOptionTxt] = useState<boolean>(false);

  const onFinish = async (values: FormData) => {
    setIsLoading(true);
    const zip = new ZipDownloader();
    if (values.optionDownload === "recommend") {
      images = images.filter((image) => image.is_recommended);
    } else if (values.optionDownload === "like") {
      images = images.filter((image) => image.is_liked);
    }

    if (values.formatDownload.includes("default")) {
      const imagelist = images.map((image) => {
        const cleanUrl =
          new URL(image.url).origin + new URL(image.url).pathname;

        return {
          url: cleanUrl,
          name: image.file_name,
          folder_id: image.folder_id,
          folder_name: image.folder_name,
        };
      });

      await zip.addMulti(
        imagelist,
        20,
        albumDetail?.pages[0]?.data?.data?.name
      );
    }
    if (values.formatDownload.includes("txt")) {
      const valueJoin = form
        .getFieldValue("formatDownloadTxt")
        .map(
          (item) =>
            formatTxtOptions.find((option) => option.value === item).join
        )
        .join("");
      const valueExtention = form
        .getFieldValue("formatDownloadTxt")
        .includes(DOWNLOAD_ALBUM.FORMAT_FILE_TXT.EXTENTION);
      const imagesGroupByFolder = images.reduce((groups, image) => {
        const key = image.folder_id;
        if (!groups[key]) {
          groups[key] = [];
        }
        groups[key].push(image);
        return groups;
      }, {} as Record<string, TImage[]>);
      const listTxt = Object.entries(imagesGroupByFolder).map(([folderId, images]) => {
          const content = '-- Folder:' + images[0].folder_name + "\n";
          return content + images
            .map((image) => {
              if (valueExtention)
                return image.file_name;
              return image.file_name.replace(/\.[^/.]+$/, "");
            })
            .join(valueJoin);
        })
        .join("\n");
      zip.file("list.txt", listTxt);
    }
    if (values.commentDownload === "1") {
      const imagesCmt = images.filter((image) => image.comments_count);
      zip.file(
        "comments.txt",
        imagesCmt
          .map(
            (image) =>
              "- File:" +
              image.file_name +
              ":\n" +
              image.comments.map((c) => c.comment).join("	  ")
          )
          .join("\n")
      );
    }

    zip.save(albumDetail?.pages[0]?.data?.data?.name);
    onClick && onClick();
    setIsLoading(false);
  };

  useEffect(() => {
    refetchAlbum();
    const options: TDownloadOptions = getDownloadOptionsFromLS();
    if (!options || Object.keys(options).length === 0) {
      form.setFieldsValue({
        commentDownload: DOWNLOAD_ALBUM.INSERT_COMMENT.NO,
        formatDownload: [DOWNLOAD_ALBUM.FORMAT_FILE.DEFAULT],
        optionDownload: DOWNLOAD_ALBUM.OPTION.ALL,
        formatDownloadTxt: [],
      });
    } else {
      if (options.formatDownload.includes("txt")) setShowOptionTxt(true);
      form.setFieldsValue(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onValuesChange={(changedValues: any, values: TDownloadOptions) => {
        if (Object.keys(changedValues)[0] === "formatDownload") {
          if (values.formatDownload?.includes("txt")) {
            setDownloadOptionsToLS({
              ...values,
              formatDownloadTxt: allOptionsTxt,
            });
            form.setFieldValue("formatDownloadTxt", allOptionsTxt);
            setShowOptionTxt(true);
          } else {
            setDownloadOptionsToLS({
              ...values,
              formatDownloadTxt: [],
            });
            form.setFieldValue("formatDownloadTxt", []);
            setShowOptionTxt(false);
          }
        } else if (
          Object.keys(changedValues)[0] === "formatDownloadTxt" &&
          values.formatDownloadTxt.length === 0
        ) {
          const temp = values.formatDownload?.filter((item) => item !== "txt");
          form.setFieldValue("formatDownload", temp);
          setDownloadOptionsToLS({
            ...values,
            formatDownload: temp,
          });
          setShowOptionTxt(false);
        } else setDownloadOptionsToLS(values);
      }}
      colon={false}
      layout="vertical"
    >
      <div className="flex justify-between flex-col sm:flex-row">
        <div className="flex flex-col">
          <Form.Item
            label={t(
              "dashboard.albums.action.download.modalDownload.optionDownload.title"
            )}
            name="optionDownload"
          >
            <Radio.Group name="optionDownload" className="flex flex-col gap-1">
              <Radio value={DOWNLOAD_ALBUM.OPTION.ALL}>
                {t(
                  "dashboard.albums.action.download.modalDownload.optionDownload.value1"
                )}
              </Radio>
              <Radio value={DOWNLOAD_ALBUM.OPTION.RECOMMEND}>
                {t(
                  "dashboard.albums.action.download.modalDownload.optionDownload.value2"
                )}
              </Radio>
              <Radio value={DOWNLOAD_ALBUM.OPTION.LIKE}>
                {t(
                  "dashboard.albums.action.download.modalDownload.optionDownload.value3"
                )}
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={t(
              "dashboard.albums.action.download.modalDownload.commentDownload.title"
            )}
            name="commentDownload"
          >
            <Radio.Group name="commentDownload" className="flex flex-col gap-1">
              <Radio value={DOWNLOAD_ALBUM.INSERT_COMMENT.YES}>
                {t(
                  "dashboard.albums.action.download.modalDownload.commentDownload.value1"
                )}
              </Radio>
              <Radio value={DOWNLOAD_ALBUM.INSERT_COMMENT.NO}>
                {t(
                  "dashboard.albums.action.download.modalDownload.commentDownload.value2"
                )}
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="flex flex-col gap-1">
          <Form.Item
            label={t(
              "dashboard.albums.action.download.modalDownload.formatDownload.title"
            )}
            name="formatDownload"
            className="mb-0"
            rules={[
              () => ({
                validator(_, value: string[]) {
                  if (!value.length) {
                    return Promise.reject(
                      new Error(
                        t(
                          "dashboard.albums.action.download.modalDownload.error_least_1"
                        )
                      )
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Checkbox.Group
              options={formatOptions}
              className="flex flex-col gap-1"
            />
          </Form.Item>
          <Form.Item
            name="formatDownloadTxt"
            className={showOptionTxt ? "visible" : "invisible"}
          >
            <Checkbox.Group
              options={formatTxtOptions.map((item) => ({
                value: item.value,
                label: item.label,
              }))}
              className={`flex flex-col gap-1 ml-2`}
            />
          </Form.Item>
        </div>
      </div>
      <Form.Item className="mb-0 flex justify-center items-center">
        <Button
          type="default"
          htmlType="submit"
          className={`transition-all success ${
            isFetching || loadingAlbum ? "opacity-70" : ""
          }`}
          disabled={isLoading || loadingAlbum || isFetching}
          loading={isLoading}
        >
          <Download className="w-4" />
          {t("dashboard.albums.action.download.modalDownload.btn")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DownloadAlbum;
