import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../locales/en/translation.json';
import translationVI from '../locales/vi/translation.json';
import { localStorageKeys } from '../constants/localStorageKeys';
import axios from 'axios';

const getLocationIP = async () => {
  try {
    const response = await axios.get("https://ipinfo.io");
    const language = response.data.country === 'VN' ? 'vi' : 'en';
    localStorage.setItem(localStorageKeys.LANGUAGE_DISPLAY, language);
    i18n.changeLanguage(language);
    return language;
  } catch (error) {
    console.error('Error:', error);
    return 'en';
  }
}

if(!localStorage.getItem(localStorageKeys.LANGUAGE_DISPLAY)){  
  getLocationIP()
}

const savedLanguage =
  localStorage.getItem(localStorageKeys.LANGUAGE_DISPLAY) || 'en';

const resources = {
  en: {
    translation: translationEN,
  },
  vi: {
    translation: translationVI,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: savedLanguage,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
