import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { TFolderAlbum } from "../../types/album.type";

const FolderAlbum = ({
  folders = [],
  onClick,
  loadingAlbum,
}: {
  folders: TFolderAlbum[];
  onClick: (values: number[]) => void;
  loadingAlbum: boolean;
}) => {
  const { t } = useTranslation();
  const tabs = [
    { folder_name: t("albumDetail.folders.all"), id: "" },
    ...folders,
  ].map((item) => {
    return {
      label: (
        <span className="block md:w-fit w-24 text-ellipsis overflow-hidden text-center">
          {item.folder_name}
        </span>
      ),
      key: item.id.toString(),
      disabled: loadingAlbum,
    };
  });

  return (
    <div className="tabs-folder">
      <Tabs
        defaultActiveKey={"0"}
        style={{ height: 220 }}
        items={tabs}
        onTabClick={(value) => {
          onClick && onClick(!!value ? [+value] : undefined);
        }}
      />
    </div>
  );
};

export default FolderAlbum;
