import { createSlice } from "@reduxjs/toolkit";
import { TPaymentStore } from "../../types/membership.type";

const initialState: TPaymentStore = {
  subscriptionId: 0,
  isCloseAllModal: false,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState: initialState,
  reducers: {
    setSubscriptionId: (state, action) => {
      return {
        ...state,
        subscriptionId: action.payload,
      };
    },
    setIsCloseAllModal: (state, action) => {
      return {
        ...state,
        isCloseAllModal: action.payload,
      };
    },
  },
});

export const { setSubscriptionId, setIsCloseAllModal } = paymentSlice.actions;

export default paymentSlice.reducer;
