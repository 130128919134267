import { useQuery } from 'react-query';
import { queryKeys } from '../../constants/queryKeys';
import { TStaticPageResponse } from '../../types/static-page.type';
import { staticPage } from '../endpoints';
import http from '../http.service';

export const useStaticPageDetail = (path: string) => {
  const query = useQuery([queryKeys.GET_STATIC_PAGE, path], async () => {
    const res = await http.get<TStaticPageResponse>(staticPage.DETAIL(path));
    return res;
  });

  return query;
};
