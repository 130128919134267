import { jwtDecode } from 'jwt-decode';

type TDecodeToken = {
  email: string;
  iat: number;
  exp: number;
};

export function validateToken(token: string): boolean {
  try {
    const decode: TDecodeToken = jwtDecode(token);
    const currentDate = Date.now() / 1000;
    return !!decode.email && decode.exp > currentDate;
  } catch (error) {
    console.log(error);
    return false;
  }
}
