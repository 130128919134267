import { GoogleOAuthProvider } from "@react-oauth/google";
import { Button, Form, Input } from "antd";
import { createRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { path } from "../../routes/path";
import { useLoginApi } from "../../services/apis/auth.api";
import { TLoginForm } from "../../types/form.type";
import GoogleLogin from "./google";

import ReCAPTCHA from "react-google-recaptcha";
import { focusValidation } from "../../utils/common";

const clientId = process.env.REACT_APP_CLIENT_ID_GOOGLE as string;

const Login = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [error, setError] = useState<string>("");
  const recaptchaRef = createRef<any>();
  const mutation = useLoginApi();
  const onFinish = (values: TLoginForm) => {
    mutation.mutate(values, {
      onError: () => {
        setError(t("formLogin.loginFail"));
      },
    });

    recaptchaRef.current.reset();
    form.setFieldValue("recaptcha_token", "");
  };
  const email = Form.useWatch("email", form);
  const password = Form.useWatch("password", form);
  const recaptcha_token = Form.useWatch("recaptcha_token", form);

  const handleRecaptcha = (value) => {
    form.setFieldValue("recaptcha_token", value);
  };

  useEffect(() => {
    if (error !== "") setError("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password]);

  return (
    <>
      <div className="relative py-4 flex flex-col items-center sm:gap-3 gap-2 md:max-w-96 m-auto">
        <div className="text-slate-700 font-semibold text-xl sm:text-2xl">
          {t("formLogin.title")}
        </div>
        <div className="text-slate-500 flex gap-1 text-sm">
          {t("formLogin.subTitle")}
          <Link
            to={path.register}
            className="text-primary hover:text-red-500/85"
          >
            {t("formLogin.linkRegister")}
          </Link>
        </div>
        <GoogleOAuthProvider clientId={clientId}>
          <GoogleLogin setError={setError} />
        </GoogleOAuthProvider>
        <div className="relative w-full text-slate-500 my-5 text-sm before:w-[30%] before:absolute before:-translate-y-1/2 before:top-1/2 before:left-0 before:h-[1px] before:bg-slate-900/30 after:w-[30%] after:absolute after:-translate-y-1/2 after:top-1/2 after:right-0 after:h-[1px] after:bg-slate-900/30">
          <span className="bg-transparent text-center w-full block">
            {t("formLogin.orUse")}
          </span>
        </div>
        {error && <div className="text-sm text-primary mr-auto">{error}</div>}
        <Form
          form={form}
          name="login-form"
          className="login-form w-full flex flex-col"
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={(errorInfo) => {
            focusValidation(errorInfo, "login-form");
          }}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: (
                  <span className="text-xs mb-1 block">
                    {t("formLogin.requiredEmail")}
                  </span>
                ),
              },
              {
                type: "email",
                message: (
                  <span className="text-xs mb-1 block">
                    {t("formLogin.invalidatedEmail")}
                  </span>
                ),
              },
            ]}
          >
            <Input
              autoFocus
              placeholder={t("formLogin.email")}
              className="py-2 px-3"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: (
                  <span className="text-xs mb-1 block">
                    {t("formLogin.requiredPassword")}
                  </span>
                ),
              },
              {
                min: 6,
                message: (
                  <span className="text-xs mb-1 block">
                    {t("formLogin.minPassword")}
                  </span>
                ),
              },
            ]}
          >
            <Input
              type="password"
              placeholder={t("formLogin.password")}
              className="py-2 px-3"
            />
          </Form.Item>
          <ReCAPTCHA
            className="flex justify-center items-center"
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={handleRecaptcha}
          />
          <Form.Item name="recaptcha_token" hidden={true}>
            <Input type="hidden" />
          </Form.Item>
          <Link
            className="ml-auto hover:text-secondary mt-3 text-slate-600"
            to={path.recovery}
          >
            {t("formLogin.forgotPass")}
          </Link>
          <Form.Item>
            <Button
              type="primary"
              block
              htmlType="submit"
              className="transition-all mt-4 font-medium"
              size="large"
              loading={mutation.isLoading}
              disabled={
                mutation.isLoading || !recaptcha_token || recaptcha_token === ""
              }
            >
              {t("formLogin.btnLogin")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default Login;
