import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  paymentNganluongCheck,
  paymentNganluongCreate,
} from "../../services/apis/payment.api";
import MessageModal, { TMessageModal } from "../../components/MessageModal";
import { useDispatch } from "react-redux";
import { setIsCloseAllModal } from "../../stores/reducers/payment.reducer";

export default function NganLuong({
  subscriptionId,
}: {
  subscriptionId: number;
}) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<Omit<
    TMessageModal,
    "isVisible"
  > | null>(null);
  const dispatch = useDispatch();

  const openPopup = (transactionData: any, popup: Window | null) => {
    const interval = setInterval(() => {
      if (popup && popup.closed) {
        clearInterval(interval);
        paymentNganluongCheck({ reference: transactionData.reference })
          .then((res) => {
            dispatch(setIsCloseAllModal(true));
            setIsModalOpen({
              title: t("servicePackages.payment.nganLuong"),
              subTitle: res.data.message,
              type: "success",
              btnText: t("btnClose"),
              onClose() {
                window.location.reload();
              },
            });
          })
          .catch((err) => {
            setIsModalOpen({
              title: t("servicePackages.payment.nganLuong"),
              subTitle: err.response?.data?.message || t("errorTitle"),
              type: "error",
              btnText: t("btnClose"),
              onClose() {
                setIsModalOpen(null);
              },
            });
          });
      }
    }, 100);
  };
  const onClick = () => {
    const width = 500;
    const height = 500;
    const left = (window.innerWidth - width) / 2 + window.screenX;
    const top = (window.innerHeight - height) / 2 + window.screenY;
  
    const popup = window.open(
      "",
      "popup",
      `width=${width},height=${height},scrollbars=yes,resizable=yes,left=${left},top=${top}`
    );
  
    paymentNganluongCreate({ subscription_id: subscriptionId })
      .then((res) => {
        if (popup) {
          popup.location.href = res.data.transaction.link;
          openPopup(res.data.transaction, popup);
        }
      })
      .catch((err) => {
        console.error("Lỗi khi tạo đơn hàng:", err);
        setIsModalOpen({
          title: t("servicePackages.payment.nganLuong"),
          subTitle: t("errorTitle"),
          type: "error",
          btnText: t("btnClose"),
          onClose() {
            setIsModalOpen(null);
          },
        });
      });
  };
  return (
    <>
      <div
        className="bg-orange-500 text-white cursor-pointer rounded-[4px] flex justify-center items-center text-base h-full"
        onClick={onClick}
      >
        {t("servicePackages.payment.nganLuong")}
      </div>
      <MessageModal
        isVisible={!!isModalOpen}
        {...isModalOpen}
        handleClick={() => {
          dispatch(setIsCloseAllModal(false));
        }}
      />
    </>
  );
}
