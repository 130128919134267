import React, { useEffect, useState } from "react";
import MessageModal from "../MessageModal";
import { Input } from "antd";
import { useTranslation } from "react-i18next";
import { useRootSelector } from "../../stores/reducers/root";
import { Phone } from "react-feather";
import { useGetMeApi, useUpdateProfileApi } from "../../services/apis/user.api";
import { TModalPayment } from "../../pages/ServicePackages";

const UpdatePhone = ({
  isVisible,
  onClick,
}: {
  isVisible: string | TModalPayment;
  onClick?: (value) => void;
}) => {
  const { t } = useTranslation();
  const user = useRootSelector((state) => state.user.userProfile);
  const { refetch } = useGetMeApi(false);
  const [value, setValue] = useState<string>("");
  const [error, setError] = useState<string>(undefined);
  const mutation = useUpdateProfileApi();
  const handlePhone = () => {
    const formData = new FormData();
    formData.append("name", user.name);
    formData.append("website", user.website);
    formData.append("facebook", user.facebook);
    formData.append("address", user.address);
    formData.append("phone_number", value);
    formData.append("description", user.description);
    formData.append("_method", "PUT");

    mutation.mutate(formData, {
      onSuccess: () => {
        onClick?.(
          typeof isVisible === "string"
            ? isVisible.replace(/^phone/, "")
            : {
                ...isVisible,
                havePhone: true,
              }
        );
        refetch();
      },
    });
  };

  useEffect(() => {
    if (!!value) {
      if (value.length > 9) setError(undefined);
      else if (!error) setError(t("formProfile.minPhone"));
    } else setError(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <MessageModal
      isVisible={!!isVisible}
      onClose={() => {
        onClick?.("");
      }}
      customIcon={<Phone className="w-12 font-extrabold" />}
      handleClick={handlePhone}
      title={t("formProfile.updatePhoneTitle")}
      subTitle={t("formProfile.updatePhoneSubTitle")}
      type="update"
      btnText={t("formProfile.btn")}
      disabledBtn={!value || value?.length < 10}
      children={
        <div className="relative mb-2">
          <Input
            type="text"
            maxLength={11}
            autoFocus={true}
            onInput={(event: React.FormEvent<HTMLInputElement>) => {
              const input = event.currentTarget;
              input.value = input.value.replace(/[^0-9]/g, "");
              setValue(input.value);
            }}
            className={`${
              !!value && value.length < 10 ? "border-primary" : ""
            }`}
          />
          <span className="text-xs absolute mt-1 block text-primary transition-all">
            {error}
          </span>
        </div>
      }
    />
  );
};

export default UpdatePhone;
